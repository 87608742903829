<template>
  <div>
    <div class="container" v-if="device === 'mobile'">
      <PriceOne
        v-for="(item, index) in roomsList"
        :key="index"
        :data="item"
        :index="index"
        :supplier="supplier"
        :hotels="totalHotelList[index]"
        @changedRoomType="triggerChangeRoomType"
        @closed="removeItem"
        @update="updateItem"
        @setPriceLoading="getPriceLoading"
      />
      <div class="chooseroom_footer">
        <div class="chooseroom_footer_top d-flex justify-content-between">
          <a href="#" onclick="return false;" @click="addRoom" class="btn btn-link" :disable="priceLoading">
            <b-spinner small type="grow" v-if="priceLoading"></b-spinner>
            <i class="fas fa-plus" v-else></i> {{ $t("product-page.add-room") }}
          </a>
          <div class="pricebox" v-if="isOdysseySite">
            <strong>₪{{ netPrice }}</strong>
            {{ $t("product-page.total-pay") }}
            <div>₪{{ totalPrice }}</div>
          </div>
          <div class="pricebox" v-else>
            <!-- {{ $t("product-page.total-per-passenger")}}
            <strong>₪{{ averagePrice }}</strong> -->
            {{ $t("product-page.total-pay") }}
            <strong>₪{{ totalPrice }}</strong>
            <s>₪{{ (totalPrice * 1.1).toFixed(0) }}</s>
          </div>
        </div>
        <button href="#" @click="gotoBook" :disabled="priceLoading" class="btn btn-primary d-block w-100 mt-3">
          {{ $t("product-page.order") }}
        </button>
      </div>
    </div>

    <div class="vacationprice_area" v-if="device === 'desktop'">
      <h3>
        {{ $t("product-page.prices") }}
      </h3>
      <div class="flight-details">
        <div class="hldr">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="flight-details-leftbox">
                <h4 :style="(lang !== 'he')?'direction: ltr;':''"><!--{{ personString }},--> <span dir="ltr">{{ daysString }} <i class="far fa-calendar-alt"></i></span></h4>
                <div class="card cardone">
                  <PriceOne
                    v-for="(item, index) in roomsList"
                    :key="index"
                    :data="item"
                    :index="index"
                    :supplier="supplier"
                    :hotels="totalHotelList[index]"
                    @changedRoomType="triggerChangeRoomType"
                    @closed="removeItem"
                    @update="updateItem"
                    @setPriceLoading="getPriceLoading"
                  />
                  <button type="button" class="add-field" @click="addRoom" :disabled="addRoomDisable || priceLoading">
                    <i class="far fa-plus-square"></i>
                    <b-spinner small type="grow" v-if="priceLoading"></b-spinner>{{ $t("product-page.add-room") }}<i class="fas fa-hospital-alt"></i>
                  </button>
                </div>
                <h4 class="pt-2" v-if="adminRemark">{{ $t("product-page.remarks")}}: {{adminRemark}}</h4>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="flight-details-leftbox">
                <h4>{{ $t("product-page.vacation-price") }}</h4>
                <div class="card cardtwo">
                  <PriceTwo
                    v-for="(item, index) in roomsList"
                    :key="index"
                    :room="item"
                    :index="index"
                    @closed="removeItem"
                  />
                  <div class="card-footer">
                    <div class="d-flex justify-content-between">
                      <div class="p-2">{{ $t("product-page.special-price") }}</div>
                      <div v-if="isOdysseySite">
                        <div class="p-2">{{ $t('search-result.net-price') }}: <span>₪{{ netPrice }}</span></div>
                      </div>
                      <div class="p-2">₪{{ totalPrice }}</div>
                    </div>
                  </div>
                </div>
                <div class="addfooterbox d-flex justify-content-between">
                  <!-- <p><img src="assets/img/breach.jpg" alt="breach" /></p> -->
                  <div v-if="isOdysseySite && !isFCLAgent">
                    <p class="p-3" v-if="supplier === 'ATLANTIS'"><span>{{ $t('search-result.commission') }}: </span>3%</p>
                    <p class="p-3" v-else><span>{{ $t('search-result.commission') }}: </span>{{ `${commission}%` }}</p>
                  </div>
                  <p v-else></p>
                  <div class="d-flex justify-content-between w-100">
                    <span class="remark">
                      {{ remark }}
                    </span>
                    <button class="btn btn-primary" :disabled="priceLoading" @click="gotoBook">
                      {{ $t("product-page.book-button") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue';
import TemplatePriceArea from './TemplatePriceArea';

export default {
  name: 'PriceAreaTheme0',
  mixins: [TemplatePriceArea],
  components: {
    BSpinner,
    PriceOne: () => import('@/components/productPage/priceOne/PriceOneTheme0'),
    PriceTwo: () => import('@/components/productPage/priceTwo/PriceTwoTheme0'),
  },
};
</script>
<style scoped>
button:disabled,
[type="button"]:disabled,
[type="reset"]:disabled,
[type="submit"]:disabled {
  cursor: default;
  opacity: 0.2;
}
</style>
<style>
button.js-del-row {
  top: -5px;
}

.remark {
  line-height: 38px;
  margin-top: 13px;
  margin-left: 10px;
}

@media (max-width: 600px) {
  div[id^="upgrade-"] .modal-dialog {
    width: 96%;
  }
  div[id^="upgrade-"] table th,
  div[id^="upgrade-"] table td {
    padding: 2px;
  }
}

@media (max-width: 768px) {
  .flightpagelist.filterItem {
    margin-top: 1em;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    padding: 5px;
  }
}
</style>
