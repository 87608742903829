import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { toBase64 } from '@/utils/base64Util';

export default {
  name: 'TemplatePriceArea',
  props: {
    hotels: {
      type: Array,
      default: () => [],
    },
    moreHotelInfo: {
      type: Object,
      default: null,
    },
    searchGuid: {
      type: String,
      default: '',
    },
    supplier: {
      type: String,
      default: '',
    },
    hotelName: {
      type: String,
      default: '',
    },
    destinationCityName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchQuery: '',
      query: null,

      personString: '',
      daysString: '',

      roomsList: [],
      maxRoomCount: 5,
      addRoomDisable: false,
      priceLoading: false,

      adult: 0,
      child: 0,
      infant: 0,
      totalPrice: 0,
      oldTotalPrice: 0,
      averagePrice: 0,
      translations: [],

      totalHotelList: [],
      product: null,

      supplierList: process.env.VUE_APP_SUPPLIER_LIST.split(','),
      transientCommission: 0,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      marketerId: 'GET_MARKETER_ID',
      subMarketerId: 'GET_SUB_AGENCY',
      partnerId: 'GET_PARTNER_ID',
      // isLogin: 'IS_AUTHORIZED',
      // memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      hotelList: 'GET_DOMESTIC_HOTELS',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      agencyOwnerName: 'GET_AGENCY_OWNER_NAME',
      affiliation: 'GET_AFFILIATION',
    }),
    adminRemark() {
      return this.supplier === 'ATLANTIS' ? this.hotels[0].remark : this.hotels[0].adminRemark;
    },
    isCardRemark() {
      let stateCardRemark = false;
      if (this.hotels[0]?.specialRemarks) {
        this.hotels[0].specialRemarks.forEach((el) => {
          if (el.remarkCategory === 'cardRemark') stateCardRemark = true;
        });
      }
      return stateCardRemark;
    },
    isRemarkWithDisc() {
      if (!this.hotels[0]?.specialRemarks) return false;
      if (!this.hotels[0]?.specialRemarks?.length) return false;
      return this.hotels[0].specialRemarks.some((el) => el.remarkCategory === 'remarkWithDisc');
    },
    commission() {
      const { hotelList, isFCLAgent, transientCommission } = this;
      const fcCommPctFromRule = this.hotels[0].fcCommPctFromRule || 3;
      if (this.supplier === 'ATLANTIS') return fcCommPctFromRule;
      const hotel = hotelList?.data?.find((ht) => ht.hotelExternalCode === this.$route.query.hotelCode);
      let cms = 0;
      if (isFCLAgent && this.hotels[0].matchingDiscInfo?.creditCardRemark) {
        cms = 10;
      } else if (this.hotels[0].matchingDiscInfo?.creditCardRemark) {
        cms = fcCommPctFromRule;
      } else if (this.isRemarkWithDisc) {
        cms = this.hotels[0]?.specialRemarks.find((el) => el.agentCommissionPct).agentCommissionPct || 0;
        if (!isFCLAgent) {
          cms -= fcCommPctFromRule;
        }
      } else if (isFCLAgent && this.isCardRemark) {
        cms = 10;
      } else if (!isFCLAgent && !this.isCardRemark) {
        // cms = fcCommPctFromRule;
        cms = +hotel?.commission - fcCommPctFromRule;
      } else if (isFCLAgent) {
        cms = (transientCommission + (hotel?.commission || 0)) || hotel?.commission || 0;
      } else if (this.hotels[0].agentCommPctFromRule) {
        cms = this.hotels[0].agentCommPctFromRule;
      } else {
        cms = Number(hotel?.commission + transientCommission) - fcCommPctFromRule;
      }

      return cms;
    },
    netPrice() {
      const { commission } = this;
      return Math.round(this.oldTotalPrice * (1 - commission / 100));
    },
    remark() {
      const hotel = this.hotels[0];

      let _remark = '';

      if (this.isOdysseySite && hotel?.supplierCode === 'ATLANTIS') {
        _remark = hotel.remarkAgent;
      } else if (hotel?.supplierCode === 'ATLANTIS') {
        _remark = hotel.remark;
      }

      if (hotel.adminRemark && hotel?.supplierCode !== 'ATLANTIS') {
        _remark = hotel.adminRemark;
      }
      if (hotel.matchingDiscInfo?.creditCardRemark && hotel?.supplierCode !== 'ATLANTIS') {
        if (_remark !== '') {
          _remark = `${_remark}, ${hotel.matchingDiscInfo?.creditCardRemark || ''}`;
        } else {
          _remark = hotel.matchingDiscInfo?.creditCardRemark || '';
        }
      }

      return _remark;
    },
  },
  watch: {
    roomsList() {
      this.addRoomDisable = this.roomsList.length === this.maxRoomCount;
      this.updateTotalPrice();
    },
    lang() {
      this.personString = this.getPersonString(this.adult, this.child, this.infant);
    },
  },
  beforeMount() {
    this.query = this.$route.query;
    this.adult = Number(this.query.adult);
    this.child = Number(this.query.child);
    this.infant = Number(this.query.infant);

    this.personString = this.getPersonString(this.adult, this.child, this.infant);

    this.daysString = `${dayjs(this.query.checkIn).format('D.M')} - ${dayjs(this.query.checkOut).format('D.M')}`;

    this.totalHotelList.push(this.hotels);

    this.roomsList.push(this.getOneItem());
  },
  methods: {
    triggerChangeRoomType(inx, packages) {
      const hotels = [];
      if (packages && packages.length > 0) {
        packages.forEach((item) => {
          item.data.data.forEach((elm) => {
            elm.supplierCode = item.supplierCode;
            elm.adminRemark = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].adminRemark : '';
            elm.agentCommPctFromRule = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].agentCommPctFromRule : null;
            elm.fcCommPctFromRule = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].fcCommPctFromRule : 0;
            elm.imgUrl = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].imageUrl : '';
            elm.searchGuid = item.searchGuid;

            this.transientCommission = item.hotelInfo[elm.exCode]?.extraCommission || 0;
            hotels.push(elm);
          });
        });
      }
      this.totalHotelList[inx] = hotels;
    },
    getPersonString(adult, child, infant) {
      let str = '';
      if (adult === 1) {
        str = `${adult} ${this.$t('product-page.adult')}`;
      } else {
        str = `${adult} ${this.$t('product-page.adults')}`;
      }
      if (child === 1) {
        str += ` + ${child} ${this.$t('product-page.child')}`;
      } else if (child > 1) {
        str += ` + ${child} ${this.$t('product-page.children')}`;
      }
      if (infant === 1) {
        str += ` + ${infant}  ${this.$t('product-page.infant')}`;
      } else if (infant > 1) {
        str += ` + ${infant}  ${this.$t('product-page.infants')}`;
      }
      return str;
    },
    removeItem(index) {
      this.roomsList.splice(index, 1);
      this.totalHotelList.splice(index, 1);
    },
    addRoom() {
      if (!this.addRoomDisable) {
        this.roomsList.push(this.getOneItem());
        this.totalHotelList.push(this.hotels);
      }
    },
    async updateItem(priceInfo) {
      Object.keys(this.roomsList[priceInfo.index]).forEach((key) => {
        this.roomsList[priceInfo.index][key] = priceInfo[key];
      });
      this.averagePrice = priceInfo.price;
      this.updateTotalPrice();
    },
    getOneItem() {
      return {
        searchGuid: '',
        roomRateGuid: '',
        roomClass: '',
        roomClassName: '',
        roomBasis: '',
        roomBasisName: '',
        adult: 0,
        child: 0,
        infant: 0,
        price: 0,
        oldPrice: 0,
        token: '',
        discountPercent: 0,
        priceList: '',
        roomTypeName: '',
        roomOccupCode: '',
        roomOccupString: '',
        category: null,
        validation: false,
        net: 0,
      };
    },
    updateTotalPrice() {
      // const { memberDiscountPercent } = this;

      this.totalPrice = this.roomsList.reduce((a, b) => +a + +b.price, 0);
      this.oldTotalPrice = this.roomsList.reduce((a, b) => +a + +b.oldPrice, 0);
      // this.netPrice = Math.round(this.oldTotalPrice * (1 - this.commission / 100));
      // const oPrice = this.roomsList.reduce((a, b) => +a + +b.price, 0);

      // const isAtlantis = this.supplier === 'ATLANTIS';
      // const isCreditCardRemarkHotel = false;
      // const price = (isAtlantis || isCreditCardRemarkHotel) ? oPrice : oPrice * (1 - memberDiscountPercent / 100);
      // this.totalPrice = (this.isLogin) ? Math.round(price) : Math.round(oPrice);

      // this.product.priceTotal = this.totalPrice;
      // window.localStorage.setItem('production', JSON.stringify(this.product));
      // this.$store.dispatch('UPDATE_ROOM_LIST', { roomList: this.roomsList });
    },
    getPriceLoading(isLoading) {
      this.priceLoading = isLoading;
    },
    async gotoBook() {
      const suplierCodeList = process.env.VUE_APP_SUPPLIER_LIST.split(',');
      const dealId = this.query.dealId || '';
      const rooms = [];
      if (this.roomsList.filter((item) => !item.validation).length > 0) {
        this.$bvModal.msgBoxOk(
          this.$t('product-page.no-hotel-message'),
          {
            title: this.$t('product-page.expire-title'),
            bodyClass: 'text-center',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return;
      }
      this.roomsList.forEach((room) => {
        const roomData = {
          searchGuid: room.searchGuid,
          roomRateGuid: room.roomRateGuid,
          bookingToken: room.token,
          totalAfterDiscount: room.price,
          netPrice: Math.round(room.oldPrice * (1 - this.commission / 100)),
          discountPercent: room.discountPercent,
          totalOriginal: room.oldPrice,
          currencyCode: 'IS',
          roomCode: room.roomClass,
          basisCode: room.roomBasis,
          priceListCode: room.priceList,
          adults: Number(room.adult),
          children: Number(room.child),
          infant: Number(room.infant),
          roomOccupancyCode: room.roomOccupCode,
          roomOccupString: room.roomOccupString,
          roomTypeName: room.roomTypeName,
          roomClassName: room.roomClassName,
          roomBasisName: room.roomBasisName,
          category: room.category,
          nett: room.net,
          paxIds: [],
        };
        rooms.push(roomData);
      });

      this.product = {
        partnerId: this.partnerId,
        agentScreenResolultion: this.device,
        grandTotalAfterDiscount: this.totalPrice,
        netPrice: this.netPrice,
        agentNetto: this.netPrice,
        priceBeforeCoupon: this.totalPrice,
        couponCode: '',
        currencyCode: 'IS',
        hotel: {
          source: suplierCodeList.indexOf(this.supplier), // this.moreHotelInfo.hotelConversion.source,
          hotelCode: this.query.hotelCode,
          cityName: this.destinationCityName,
          checkinDate: this.query.checkIn,
          checkoutDate: this.query.checkOut,
          remarkInVoucher: this.adminRemark,
          hotelName: this.hotelName,
          cityCode: this.query.city,
          hotelSupplierCode: this.supplier,
          rooms,
        },
        paxList: [],
        payer: null,
        dealId,
      };

      const data = {
        data: this.product,
        sendingStage: 'init',
      };

      const response = await this.$store.dispatch('SEND_BOOKING_DATA', data);
      this.product.moreHotelInfo = this.moreHotelInfo;
      window.localStorage.setItem('production', toBase64(JSON.stringify(this.product)));

      if (!this.isOdysseySite) {
        const name = `${this.hotelName} - ${this.destinationCityName} - ${this.agencyOwnerName}`;

        this.$store.dispatch('SENDING_GA_EVENT', {
          eventName: 'view_item',
          currency: 'ILS',
          value: this.totalPrice,
          items: [
            {
              item_id: response.id,
              item_name: name,
              affiliation: this.affiliation,
              item_category: this.destinationCityName,
              item_category2: `${this.query.checkIn} - ${this.query.checkOut}`,
              price: this.totalPrice,
            },
          ],
        });
        // window.gtag('event', 'view_item', {
        //   currency: 'ILS',
        //   value: this.totalPrice,
        //   items: [
        //     {
        //       item_id: response.id,
        //       item_name: name,
        //       affiliation: this.affiliation,
        //       coupon: '',
        //       discount: '',
        //       index: 0,
        //       item_brand: '',
        //       item_category: this.destinationCityName,
        //       item_category2: `${this.query.checkIn} - ${this.query.checkOut}`,
        //       item_category3: '',
        //       item_category4: '',
        //       item_category5: '',
        //       item_list_id: '',
        //       item_list_name: '',
        //       item_variant: '',
        //       location_id: '',
        //       price: this.totalPrice,
        //       quantity: 1,
        //     },
        //   ],
        // });

        sessionStorage.setItem('ga-event-data', JSON.stringify({
          destination: this.destinationCityName,
          checkIn: this.query.checkIn,
          checkOut: this.query.checkOut,
        }));
      }

      if (response.success) {
        window.localStorage.setItem('BookingDataID', response.id);
        window.localStorage.setItem('retryState', 'null');

        const urlBookingInfoPage = dealId ? `/booking/hotel/?dealId=${dealId}` : '/booking/hotel';
        let body = {};
        const { marketerId, subMarketerId, partnerId, lang } = this;
        if (marketerId && marketerId !== '') body = { marketerId };
        if (subMarketerId && subMarketerId !== '') body = { ...body, subMarketerId };
        if (partnerId) body = { ...body, partnerId };
        if (lang) body = { ...body, lang };
        this.$router.push({ path: urlBookingInfoPage, query: body });
      } else {
        window.localStorage.setItem('BookingDataID', '');
      }
    },
  },
};
